var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.enableBlind)?_c('div',{staticClass:"d-flex align-center mt-4 pb-8 gap-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('wallet-connect-guard',[_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.createPostController.show(true, undefined, undefined, undefined, _vm.postStore)}}},'button',attrs,false),on),[_c('v-img',{attrs:{"src":require("@/assets/icons/double-quotes.svg"),"max-width":"24","contain":""}})],1)])]}}],null,false,2397735119)},[_c('span',[_vm._v("Quote")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('wallet-connect-guard',[_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.postStore.handleBookmark()}}},'button',attrs,false),on),[_c('v-img',{attrs:{"src":require(("@/assets/icons/bookmark-" + (_vm.postStore.post.isBookmarked ? 'active-' : '') + "ic.svg")),"max-width":"24","contain":""}})],1)])]}}],null,false,3337148290)},[_c('span',[_vm._v("Bookmark")])])],1),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"8px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('wallet-connect-guard',[_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.requestReply()}}},'button',attrs,false),on),[_c('v-img',{attrs:{"src":require("@/assets/icons/comment-icon.svg"),"max-width":"24","contain":""}})],1)])]}}],null,false,1837719150)},[_c('span',[_vm._v("Reply")])]),_vm._v(" "+_vm._s(_vm._f("_get")(_vm.postStore.post,'commentCount'))+" ")],1),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"8px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('wallet-connect-guard',[_c('button',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.postStore.handleLike()}}},'button',attrs,false),on),[_c('v-img',{attrs:{"src":require(("@/assets/icons/heart-" + (_vm.postStore.post.isLike ? 'active-' : '') + "icon.svg")),"max-width":"24","contain":""}})],1)])]}}],null,false,3347876790)},[_c('span',[_vm._v("Like")])]),_vm._v(" "+_vm._s(_vm._f("_get")(_vm.postStore.post,'likeCount'))+" ")],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.postStore.handleSharePost()}}},'button',_vm.$vuetify.breakpoint.width >= 1280 && attrs,false),_vm.$vuetify.breakpoint.width >= 1280 && on),[_c('v-img',{attrs:{"src":require("@/assets/icons/share-icon.svg"),"max-width":"24","contain":""}})],1)]}}],null,false,3794723022)},[_c('span',[_vm._v("Share")])])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }